<template>
  <v-expansion-panel>
    <v-expansion-panel-header class="headline">
      <div>
        <span>{{ $tc('label.pagamento_parciais_recebidos', 1)}}</span>
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-data-table
          :headers="headers"
          :items="pagamentos"
          :items-per-page="10"
          class="elevation-0">
        <template v-slot:item.valor="{ item }">
          {{ getMoney(item.valor) }}
        </template>
        <template v-slot:item.valorPendente="{ item }">
          {{ getMoney(item.valorPendente) }}
        </template>
        <template v-slot:item.origem="{ item }">
          {{ $t(`origem_registro.${item.origem}`) }}
        </template>
      </v-data-table>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { getMoney } from '@/produto/common/functions/helpers';

export default {
  name: 'PagamentoDetalhesParcial',
  props: {
    pagamento: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      pagamentoResource: this.$api.pagamento(this.$resource),
      pagamentos: [],
      headers: [
        { text: this.$tc('label.valor_pago', 1), value: 'valor', sortable: false },
        { text: this.$tc('label.pendente_de_pagamento', 1), value: 'valorPendente', sortable: false },
        { text: this.$tc('label.numero_doc_compensacao', 1), value: 'numDocumento', sortable: false },
        { text: this.$tc('label.data_compensacao', 2), value: 'dtaLiquidacao', sortable: false },
        { text: this.$tc('label.data_integracao_importacao', 2), value: 'dtaCriacao', sortable: false },
        { text: this.$tc('label.origem', 1), value: 'origem', sortable: false },
        { text: this.$tc('label.usuario_importacao', 1), value: 'usrCriacao', sortable: false },
      ],
    };
  },
  watch: {
    idPagamento() {
      this.buscarPagamento();
    },
  },
  methods: {
    getMoney,
    buscarPagamento() {
      const param = {
        idPagamento: this.pagamento.id,
      };
      this.pagamentoResource.buscarPagamentosParciais(param)
        .then((res) => {
          this.pagamentos = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
  },
  mounted() {
    if (this.pagamento && this.pagamento.id) {
      this.buscarPagamento();
    }
  },
};
</script>
