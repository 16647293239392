<template>
  <div>
    <v-container pa-0 fluid v-if="breadcrumbs.length > 0">
      <v-row align="center" justify="space-between" class="font-weight-black Barra_Breadcrumbs">
        <v-col cols="12" >
          <v-breadcrumbs :items="breadcrumbs" divider="/">
            <template v-slot:item="props">
              <v-breadcrumbs-item
                :disabled="props.item.disabled"
                :class="{
                    'LiquidacaoNivel_breadcrumbs-item': true,
                    'LiquidacaoNivel_breadcrumbs-item_disabled': props.item.disabled,
                  }"
                @click.native="navegarBreadcrumbs(props.item)">
                  {{ props.item.text }}
              </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs>
        </v-col>
      </v-row>
    </v-container>

    <v-card class="Nivel3" v-if="carregouMetadados">
      <div class="text-xs-center Paginacao_Detalhes" v-if="pagamentos.length && pagamentos.length > 1">
        <v-pagination
          v-model="page"
          class="py-2"
          :length="pagamentos.length"
          :total-visible="10"
          >
        </v-pagination>
        <v-divider></v-divider>
      </div>

      <pagamento-detalhes-resumo
        v-if="carregouConfiguracao && !!acao && abrirEdicao"
        :acao="acao"
        :configuracao="configuracao"
        :exibe-valor-planejado="!acaoComApuracao"
        @RECARREGAR_PAGAMENTO="recarregar">
        <div slot="depoisTitulo">
          <pagamento-acao-fluxo ref="fluxoApuracao"
                                v-if="!!acao.idAcao"
                                :acao="acao"
                                :id-acao="acao.idAcao"
                                :exibir-solicitar-aprovacao="evidenciaObrigatoriaEnviada"
                                :perguntar-proximo="page < apuracoes.length"
                                :observacao-acao="acao.observacaoAcao"
                                :ind-habilita-fluxo="acao.indHabilitaFluxo"
                                @recarregar="recarregar"
                                @PagamentoAcaoFluxo_irProximo="page++"/>
        </div>
      </pagamento-detalhes-resumo>

      <v-divider></v-divider>

      <v-expansion-panels v-model="panel" class="Painel_Detalhes_Acao" multiple accordion>
        <pagamento-detalhes-foco :acao="acao"/>
        <pagamento-detalhes-apuracao v-if="carregouConfiguracao && acaoComApuracao"
          ref="detalhesApuracao" :acao="acao" :configuracao="configuracao"/>
        <pagamento-complemento-informacoes
          v-if="visualizacaoComplementoInformacoes && metadadosComplementoInformacoes"
          ref="complementoInformacoes"
          :metadados="metadadosComplementoInformacoes"
          :acao="acao"
          :configuracao="configuracao"/>
        <pagamento-detalhes-pagamento v-if="carregouConfiguracao && exibeDadosPagamento"
          ref="detalhesPagamento" :acao="acao" :configuracao="configuracao"/>
        <pagamento-detalhes-parcial
            v-if="indLiquidacaoParcial"
            ref="detalhesPagamentoParcial"
            :pagamento="objetoPagamentoAcao"/>
        <planejamento-acao-edicao-panel
          v-if="carregouConfiguracao && !!acao && abrirEdicao"
          :exibir-edicao="!acao.pagamentosFinalizados"
          :id-acao="acao.idAcao"
          :acao-edicao="acao"
          :configuracao="configuracao"
          @PlanejamentoAcaoEdicaoPanel__atualizado="carregarDadosPagamento(objetoPagamentoAcao.id)">
        </planejamento-acao-edicao-panel>
        <pagamento-arquivos
          :acao="acao"
          v-if="indHabilitarPagamentoManual || indHabilitarEvidencia"
          :habilitar-evidencia="indHabilitarEvidencia"
          @PagamentoArquivos_uploadSucesso="recarregar"
          @PagamentoArquivos_removido="recarregar">
        </pagamento-arquivos>
      </v-expansion-panels>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PagamentoAcaoFluxo from './PagamentoAcaoFluxo';
import PagamentoDetalhesParcial from './PagamentoDetalhesParcial';
import PagamentoDetalhesResumo from './PagamentoDetalhesResumo';
import PagamentoDetalhesFoco from './PagamentoDetalhesFoco';
import PagamentoDetalhesApuracao from './PagamentoDetalhesApuracao';
import PagamentoDetalhesPagamento from './PagamentoDetalhesPagamento';
import PagamentoComplementoInformacoes from './PagamentoComplementoInformacoes';
import PlanejamentoAcaoEdicaoPanel from '../../planejamento-acao/edicao-aprovado/PlanejamentoAcaoEdicaoPanel';
import PagamentoArquivos from './PagamentoArquivos';
import { copyObject } from '../../../common/functions/helpers';

export default {
  props: {
    apuracoes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      pagamentoResource: this.$api.pagamento(this.$resource),
      planejamentoAcaoListagemResource: this.$api.planejamentoAcaoListagem(this.$resource),
      configuracaoResource: this.$api.planejamentoAcaoConfiguracao(this.$resource),
      metadadoResource: this.$api.metadado(this.$resource),

      metadadosComplementoInformacoes: null,
      page: -1,
      panel: [0, 1, 2, 3],
      iniciandoPaginacao: true,
      acao: null,
      configuracao: null,
      objetoPagamentoAcao: {},
      visao: '',
      breadcrumbs: {},
      pagamentos: [],
      dashInicial: false,
      abrirEdicao: false,

      camposPadraoLiquidacao: [
        { campo: 'numero_documento', label: this.$tc('label.numero_documento', 1), nome_campo: 'numeroDocumento' },
        { campo: 'data_liquidacao', label: this.$tc('label.data_liquidacao', 1), nome_campo: 'dataLiquidacao' },
      ],
      camposLiquidacaoManual: [
        { campo: 'nota_fiscal', label: this.$tc('label.nota_fiscal', 1), nome_campo: 'nota_fiscal' },
        { campo: 'nota_fiscal_envio_recebimento', label: this.$tc('label.nota_fiscal_envio_recebimento', 1), nome_campo: 'nota_fiscal_envio_recebimento' },
        { campo: 'nota_fiscal_vencimento', label: this.$tc('label.nota_fiscal_vencimento', 1), nome_campo: 'nota_fiscal_vencimento' },
        { campo: 'service', label: this.$tc('label.service', 1), nome_campo: 'service' },
        { campo: 'nota_fiscal_chave_cobertura', label: this.$tc('label.nota_fiscal_chave_cobertura', 1), nome_campo: 'nota_fiscal_chave_cobertura' },
        { campo: 'status_interno', label: this.$tc('label.status_interno', 1), nome_campo: 'status_interno' },
        { campo: 'status_observacao', label: this.$tc('label.status_observacao', 1), nome_campo: 'status_observacao' },
        { campo: 'evidencia', label: this.$tc('label.evidencia_apuracao', 1), nome_campo: 'evidencia' },
        { campo: 'status_pagamento', label: this.$tc('label.status_pagamento', 1), nome_campo: 'statusPagamento' },
        { campo: 'valor_acao', label: this.$tc('label.valor_acao', 1), nome_campo: 'valorAcao' },
        { campo: 'total_pagamento', label: this.$tc('label.total_pagamento', 2), nome_campo: 'totalPagamentos' },
        { campo: 'pendente_pagamento', label: this.$tc('label.pendente_pagamento', 1), nome_campo: 'pendentePagamento' },
        { campo: 'valor_pagamento', label: this.$tc('label.valor_pagamento', 1), nome_campo: 'valorPagamento' },
      ],
    };
  },
  components: {
    PagamentoDetalhesParcial,
    PagamentoAcaoFluxo,
    PlanejamentoAcaoEdicaoPanel,
    PagamentoDetalhesResumo,
    PagamentoDetalhesFoco,
    PagamentoDetalhesApuracao,
    PagamentoDetalhesPagamento,
    PagamentoComplementoInformacoes,
    PagamentoArquivos,
  },
  computed: {
    carregouConfiguracao() {
      return this.configuracao != null;
    },
    indEvidenciaManualObrigatorio() {
      return this.indHabilitarEvidencia && this.configuracao.passo6.liquidacao.indEvidencia;
    },
    evidenciaObrigatoriaEnviada() {
      if (this.indEvidenciaManualObrigatorio) {
        return this.acao.temEvidencia;
      }
      return true;
    },
    acaoComApuracao() {
      if (!this.configuracao) {
        return false;
      }
      const { passo1, passo5 } = this.configuracao;
      return passo1.fluxo.indexOf('APURACAO') >= 0 && !!passo5;
    },
    exibeDadosPagamento() {
      // Exibe nestes status pois neles há o campo Justificativa
      if (this.acao.statusPagamento === 'CANCELADO' || this.acao.statusPagamento === 'BLOQUEADO') {
        return true;
      }
      if (!this.configuracao || !this.configuracao.passo3) {
        return false;
      }
      const { listaPadraoLiquidacao, listaLiquidacaoManual } = this.configuracao.passo3;
      if (!listaPadraoLiquidacao && !listaLiquidacaoManual) {
        return false;
      }
      let possuiCampoPreenchido = false;
      if (listaPadraoLiquidacao) {
        listaPadraoLiquidacao.filter((c) => c.fluxos && c.fluxos.includes('LIQUIDACAO')).forEach((c) => {
          const nomeCampo = this.camposPadraoLiquidacao
            .filter((item) => item.campo === c.label)[0].nome_campo;
          if (this.acao[nomeCampo]) {
            possuiCampoPreenchido = true;
          }
        });
      }
      if (listaLiquidacaoManual) {
        listaLiquidacaoManual.filter((c) => c.fluxos && c.fluxos.includes('LIQUIDACAO')).forEach((c) => {
          const nomeCampo = this.camposLiquidacaoManual
            .filter((item) => item.campo === c.label)[0].nome_campo;
          if (this.acao[nomeCampo]) {
            possuiCampoPreenchido = true;
          }
        });
      }

      return possuiCampoPreenchido;
    },
    ...mapGetters('metadados', [
      'getOrcamentoMetadado',
      'getLinhaInvestimentoMetadado',
      'getProdutoMetadado',
      'getClienteMetadado',
      'getCentroCustoMetadado',
      'getDivisaoMetadado',
      'getApuracaoMetadado',
    ]),
    visualizacaoComplementoInformacoes() {
      if (!this.configuracao || !this.configuracao.passo3
        || !this.configuracao.passo3.listaDinamica) {
        return false;
      }
      return this.configuracao.passo3.listaDinamica
        .filter((campo) => campo.etapasFluxo
          && campo.etapasFluxo.filter((etapa) => etapa === 'APURACAO').length > 0).length > 0;
    },
    carregouMetadados() {
      const produtoMetadados = this.getProdutoMetadado;

      if (produtoMetadados != null) {
        return true;
      }
      return false;
    },
    indHabilitarPagamentoManual() {
      if (!this.configuracao || !this.configuracao.passo6
        || !this.configuracao.passo6.liquidacao) {
        return false;
      }
      return this.configuracao.passo6.liquidacao.indLiquidacaoManual;
    },
    indHabilitarEvidencia() {
      if (!this.configuracao || !this.configuracao.passo6
        || !this.configuracao.passo6.liquidacao) {
        return false;
      }
      return this.configuracao.passo6.liquidacao.indHabilitarEvidencia;
    },
  },
  watch: {
    page: {
      handler() {
        this.objetoPagamentoAcao = this.pagamentos[this.page - 1];
        this.setFiltroLiquidacaoContratoNivel3(this.objetoPagamentoAcao);

        this.carregarDadosPagamento(this.objetoPagamentoAcao.id)
          .then(() => {
            if (!this.iniciandoPaginacao) {
              this.trocarPaginaPagamento();
            } else {
              this.iniciandoPaginacao = false;
            }
          });
      },
    },
  },
  methods: {
    ...mapActions('filtros', [
      'setFiltroLiquidacaoContratoNivel3',
    ]),
    ...mapActions('metadados', [
      'setProdutoMetadado',
      'setApuracaoMetadado',
    ]),
    trocarPaginaPagamento() {
      const params = {
        id: this.objetoPagamentoAcao.id,
        idContrato: this.objetoPagamentoAcao.codContrato,
        idAcao: this.objetoPagamentoAcao.codAcao,
        status: this.$route.params.status,
        origem: this.$route.params.origem,
      };
      this.$router.push({ name: this.$route.name, params });
    },
    carregarAcao(idAcao) {
      return this.planejamentoAcaoListagemResource.buscarAcao({ idAcao })
        .then((res) => {
          const { passoGeral, passoComportamento } = res.data;
          this.acao = {
            ...this.acao,
            ...passoGeral.mapaExtensoes,
            ...passoGeral.mapaCamposDinamicos,
            valor: this.acao.valor != null ? this.acao.valor : passoGeral.valor,
          };
          if (passoComportamento) {
            this.acao = {
              ...this.acao,
              ...passoComportamento.mapaExcecoes,
            };
          }
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    carregarDadosPagamento(idPagamento) {
      this.abrirEdicao = false;
      return this.pagamentoResource.buscarPagamento({ idPagamento })
        .then((res) => {
          this.acao = { ...res.data };
        }).then(() => {
          Promise.all([
            this.carregarConfiguracao(this.acao.idAcao),
            this.carregarAcao(this.acao.idAcao),
            this.preencherBreadcrumbsDash(),
          ]).then(() => {
            this.abrirEdicao = true;
            if (this.acaoComApuracao) {
              this.$refs.detalhesApuracao.formatarObjeto();
            }
          });
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    preencherBreadcrumbsDash() {
      if (this.dashInicial) {
        this.breadcrumbs.push({
          nivel: 2,
          disabled: true,
          to: '',
          text: `${this.acao.idContrato} - ${this.acao.idAcao} - ${this.acao.idApuracao}`,
        });
      }
    },
    carregarConfiguracao(idAcao) {
      return this.configuracaoResource.buscarConfigSnapshot({ idAcao }).then((res) => {
        this.configuracao = res.data;
      })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    navegarBreadcrumbs(item) {
      if (item.disabled) {
        return;
      }
      if (item.nivel === 2) {
        this.breadcrumbs.splice(2, 1);
      } else {
        this.breadcrumbs.splice(1, 2);
      }

      this.$router.push({
        name: item.to,
        params: {
          visao: this.visao,
          objeto_acao: this.objetoPagamentoAcao,
          breadcrumbsParam: this.breadcrumbs,
          status: item.status,
          idContrato: item.idContrato,
        },
      });
    },
    recarregar(novoStatus = null) {
      if (this.$route.params.status != null && novoStatus != null) {
        const params = { ...this.$route.params };
        params.status = novoStatus;
        this.pagamentos = [this.objetoPagamentoAcao];
        this.$router.push({ name: 'detalharPagamentoContratoStatus', params });
      }
      if (this.$route.params.id) {
        this.carregarDadosPagamento(this.$route.params.id);
      }
    },
    setMetadadosComplementoInformacoes() {
      this.metadadosComplementoInformacoes = copyObject(this.getApuracaoMetadado);
    },
    indLiquidacaoParcial() {
      if (!this.configuracao || !this.configuracao.passo6
          || !this.configuracao.passo6.liquidacao) {
        return false;
      }
      return this.configuracao.passo6.liquidacao.indLiquidacaoParcial;
    },
  },
  beforeMount() {
    this.setProdutoMetadado({
      resource: this.metadadoResource.listarProduto,
    });

    this.setApuracaoMetadado({
      resource: this.metadadoResource.listarApuracao,
      params: null,
      callback: this.setMetadadosComplementoInformacoes,
    });

    this.objetoPagamentoAcao = {
      id: this.$route.params.id,
      codAcao: this.$route.params.idAcao,
      codContrato: this.$route.params.idContrato,
    };

    this.pagamentos = this.$route.params.liquidacoes
      ? this.$route.params.liquidacoes
      : [this.objetoPagamentoAcao];

    this.origem = this.$route.params.origem;
    this.dashInicial = this.$route.params.from === 'dashboard-inicial';

    if (this.$route.params.breadcrumbsParam) {
      this.breadcrumbs = this.$route.params.breadcrumbsParam;
    }
  },
  mounted() {
    if (this.objetoPagamentoAcao.id) {
      this.setFiltroLiquidacaoContratoNivel3(this.objetoPagamentoAcao);
      if (this.pagamentos && this.pagamentos.length > 0) {
        const pagamento = this.pagamentos
          .filter((aa) => aa.id === this.objetoPagamentoAcao.id)[0];
        if (pagamento) {
          this.page = this.pagamentos.indexOf(pagamento) + 1;
        }
      } else {
        this.carregarDadosPagamento(this.objetoPagamentoAcao.id);
      }
    }
  },
};
</script>
<style lang="scss">
  @import 'src/config/theme/theme.scss';

  .Barra_Breadcrumbs {
    .v-breadcrumbs {
      padding: 0.5em 0 1em 0;
    }
    ul:not(.md-list)>li+li {
      margin-top: 0 !important;
    }
  }
  .Paginacao_Detalhes {
    ul:not(.md-list)>li+li {
      margin-top: 0 !important;
    }
  }
  .Nivel3 .card-header {
    background-color: #fff;
    color: #000;
  }
  .Painel_Detalhes_Acao .v-expansion-panel__header__icon{
    margin-right: 1em;
  }
  .Painel_Detalhes_Acao .v-expansion-panel__header{
    padding:0;
    margin-left: 1.5em;
    min-height: 42px;
  }
  .LiquidacaoNivel_breadcrumbs-item_disabled {
    color: grey;
    pointer-events: none;
  }
  .LiquidacaoNivel_breadcrumbs-item {
    color: grey;
    cursor: pointer;
  }
  .LiquidacaoNivel_breadcrumbs-item:hover {
    color: #3f3f3f;
  }
</style>
