<template>
  <v-row justify="end" align="end">
    <slot name="antesAcoes"></slot>
    <slot name="antesAcoes2"></slot>
    <acao-fluxo
        v-if="mostrarJustificativa"
        ref="acaoMostrarJustificativa"
        icone="info"
        :mostrarRodape="false"
        @click.native="preencheInformacoesJustificativa"
        :texto-acao="$tc('label.justificativa', 1)">
    </acao-fluxo>

    <v-tooltip left v-if="mostrarAcompanhamento">
      <template v-slot:activator="{ on }">
        <v-btn text icon
               v-on="on"
               @click.native="abrirAcompanhamento">
          <v-icon >assignment_turned_in</v-icon>
        </v-btn>
      </template>
      <span>{{ $t('label.acompanhamento') }}</span>
    </v-tooltip>

    <div v-if="apresentaJustificativaAoSolicitar">
      <acao-fluxo
          v-if="podeSolicitarAprovacao"
          ref="acaoSolicitarAprovacao"
          icone="send"
          :texto-acao="$t('label.solicitar_aprovacao')"
          :status="status"
          @AcaoFluxo_executarAcao="iniciaFluxo"
          :perguntar-proximo="perguntarProximo">
      </acao-fluxo>
    </div>
    <div v-else>
      <popover-confirmacao
          v-if="podeSolicitarAprovacao"
          :message="$tc('message.deseja_solicitar_aprovacao_pagamento', 1)"
          @agree="iniciaFluxo"
          icone="send"
          :textoAcao="$t('label.solicitar_aprovacao')">
      </popover-confirmacao>
    </div>

    <acao-fluxo
        v-if="podeCancelarPagamento && !podeRecuperarPagamento"
        ref="acaoCancelar"
        icone="cancel"
        :mostrarRodape="true"
        :validarJustificativa="true"
        @AcaoFluxo_executarAcao="cancelarPagamento"
        :texto-acao="$tc('message.deseja_cancelar_pagamento', 1)">
    </acao-fluxo>

    <acao-fluxo
        v-if="podeRecuperarPagamento"
        ref="acaoCancelar"
        icone="refresh"
        :mostrarRodape="true"
        :validarJustificativa="true"
        @AcaoFluxo_executarAcao="cancelarPagamento"
        :texto-acao="$tc('label.recuperar_pagamento', 1)">
    </acao-fluxo>

    <acao-fluxo
        ref="acaoAprovar"
        icone="thumb_up"
        :id-acao="idAcao"
        :texto-acao="$t('label.aprovar')"
        :acao="acao"
        :status="status"
        :configuracao="config"
        :perguntar-proximo="perguntarProximo"
        v-if="exibeAprovar"
        @AcaoFluxo_executarAcao="aprovar">
    </acao-fluxo>

    <acao-fluxo
        ref="acaoEmAnalise"
        icone="announcement"
        :texto-acao="$t('label.solicitar_analise')"
        :status="status"
        :perguntar-proximo="perguntarProximo"
        :validar-justificativa="true"
        v-if="exibeAprovar"
        @AcaoFluxo_executarAcao="enviarAnalise">
    </acao-fluxo>

    <acao-fluxo
        ref="acaoReprovar"
        icone="thumb_down"
        :texto-acao="$t('label.reprovar')"
        :status="status"
        :perguntar-proximo="perguntarProximo"
        :validar-justificativa="true"
        v-if="exibeReprovar"
        @AcaoFluxo_executarAcao="reprovar">
    </acao-fluxo>

    <acompanhamento
        ref="modalAcompanhamento"
        :fluxo="statusPassos"/>

  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import AcaoFluxo from '@/shared-components/AcaoFluxo';
import { generateComputed } from '../../../common/functions/roles-computed-generator';
import Confirm from '../../../shared-components/vuetify/dialog/Confirm';
import Acompanhamento from '../../../shared-components/workflow/Acompanhamento';
import PopoverConfirmacao from '../../../shared-components/PopoverConfirmacao';

export default {
  name: 'PagamentoAcaoFluxo',
  props: {
    idAcao: Number,
    idPagamento: Number,
    status: String,
    acao: {
      type: Object,
      default: () => ({}),
    },
    exibirAcompanhamento: {
      type: Boolean,
      default: true,
    },
    exibirSolicitarAprovacao: {
      type: Boolean,
      default: true,
    },
    perguntarProximo: {
      type: Boolean,
      default: false,
    },
    indJustificativaAnalise: {
      type: Boolean,
      default: false,
    },
    indHabilitaFluxo: {
      type: Boolean,
      default: false,
    },
    observacaoAcao: {
      type: String,
      default: '',
    },
    tiposVerbas: String,
    podeCancelar: Boolean,
    podeReprovar: Boolean,
  },
  components: {
    PopoverConfirmacao,
    AcaoFluxo,
    Confirm,
    Acompanhamento,
  },
  data() {
    return {
      workflowPagamentoAcaoResource: this.$api.workflowPagamentoAcao(this.$resource),
      configuracaoResource: this.$api.planejamentoAcaoConfiguracao(this.$resource),
      solicitanteFluxo: false,
      aprovadorFluxo: false,
      statusPassos: {},
      config: {},
    };
  },
  computed: {
    ...generateComputed('PGTO', [
      'canEdit',
    ]),
    ...mapGetters('roles', [
      'getAllRoles',
    ]),
    podeSolicitarAprovacao() {
      return this.canEdit
          && (this.getStatusPagamento() === 'PENDENTE' || this.getStatusPagamento() === 'EM_ANALISE')
          && this.solicitanteFluxo && this.exibirSolicitarAprovacao && this.indHabilitaFluxo;
    },
    podeCancelarPagamento() {
      return this.canEdit
          && this.solicitanteFluxo
          && this.getPodeCancelar();
    },
    podeRecuperarPagamento() {
      return this.canEdit
          && this.solicitanteFluxo
          && this.isStatusVencido;
    },
    exibeAprovar() {
      return this.getStatusPagamento() === 'AGUARDANDO_APROVACAO' && this.aprovadorFluxo;
    },
    exibeReprovar() {
      return this.exibeAprovar && this.getPodeReprovar();
    },
    mostrarAcompanhamento() {
      return this.exibirAcompanhamento && !this.isStatusPendente && !this.isStatusVencido
          && !this.isStatusCancelado;
    },
    isStatusAguardandoAprovacao() {
      return this.getStatusPagamento() === 'AGUARDANDO_APROVACAO';
    },
    isStatusPendente() {
      return this.getStatusPagamento() === 'PENDENTE';
    },
    isStatusVencido() {
      return this.getStatusPagamento() === 'VENCIDO';
    },
    isStatusEmAnalise() {
      return this.getStatusPagamento() === 'EM_ANALISE';
    },
    isStatusCancelado() {
      return this.getStatusPagamento() === 'CANCELADO';
    },
    apresentaJustificativaAoSolicitar() {
      return this.indHabilitaFluxo && this.indJustificativaAnalise
          && this.isStatusEmAnalise;
    },
    mostrarJustificativa() {
      return this.indHabilitaFluxo && this.indJustificativaAnalise
          && this.isStatusAguardandoAprovacao && this.observacaoAcao;
    },
  },
  methods: {
    preencheInformacoesJustificativa() {
      if (!this.observacaoAcao) {
        return;
      }
      this.$refs.acaoMostrarJustificativa
        .preencheJustificativa(this.observacaoAcao);
    },
    carregaInformacoesFluxo() {
      if (this.getIdPagamento() && this.getStatusPagamento()) {
        this.verificarUsuarioSolicitante(this.getIdPagamento());
        this.verificarUsuarioAprovador(this.getIdPagamento());
      }
    },
    verificarUsuarioSolicitante(idPagamento) {
      if (this.isStatusPendente
          || this.isStatusVencido
          || this.isStatusEmAnalise
          || this.isStatusAguardandoAprovacao) {
        this.workflowPagamentoAcaoResource.solicitante({ idPagamento })
          .then((res) => {
            this.solicitanteFluxo = res.data.usuarioHabilitado;
            this.$forceUpdate();
          });
      }
    },
    iniciaFluxo(observacao = null) {
      const idPagamento = this.getIdPagamento();
      this.solicitaAprovacao(idPagamento, observacao);
    },
    solicitaAprovacao(idPagamento, observacao = '') {
      this.workflowPagamentoAcaoResource.iniciaFluxo({ idPagamento }, { idPagamento, observacao })
        .then(() => {
          this.$toast(this.$t('message.solicitacao_aprovacao'));
          this.$emit('recarregar', 'AGUARDANDO_APROVACAO');
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    verificarUsuarioAprovador(idPagamento) {
      if (this.isStatusAguardandoAprovacao) {
        this.workflowPagamentoAcaoResource.aprovador({ idPagamento })
          .then((response) => {
            if (response.data.usuarioHabilitado) {
              this.aprovadorFluxo = true;
            } else {
              this.aprovadorFluxo = false;
            }
            this.$forceUpdate();
          });
      }
    },
    reprovar(justificativa, irProximo) {
      const idPagamento = this.getIdPagamento();
      this.workflowPagamentoAcaoResource.reprovar({ idPagamento },
        { observacao: justificativa })
        .then(() => {
          this.$emit('recarregar', 'REPROVADO');
          this.irProximaPagina(irProximo);
          this.$toast(this.$t('message.pagamento_reprovada'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    aprovar(justificativa, irProximo) {
      const idPagamento = this.getIdPagamento();
      this.workflowPagamentoAcaoResource.aprovarPasso({ idPagamento },
        { observacao: justificativa })
        .then(() => this.passoAprovado(idPagamento, irProximo))
        .catch((err) => {
          this.$error(this, err);
        });
    },
    passoAprovado(idPagamento, irProximo) {
      this.workflowPagamentoAcaoResource.status({ idPagamento })
        .then((res) => {
          if (res.data.statusFluxo === 'APROVADO') {
            this.$emit('recarregar', 'APROVADO');
          }
          this.verificarUsuarioAprovador(this.getIdPagamento(), this.getStatusPagamento());
          this.irProximaPagina(irProximo);
          this.$toast(this.$t('message.pagamento_aprovada'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    enviarAnalise(justificativa, irProximo) {
      const idPagamento = this.getIdPagamento();
      this.workflowPagamentoAcaoResource.enviarAnalise({ idPagamento },
        { observacao: justificativa })
        .then(() => {
          this.$emit('recarregar', 'EM_ANALISE');
          this.irProximaPagina(irProximo);
          this.$toast(this.$t('message.pagamento_enviada_analise'));
        })
        .catch((err) => {
          this.$error(this, err);
        });
    },
    cancelarPagamento(observacao) {
      const idPagamento = this.getIdPagamento();
      this.workflowPagamentoAcaoResource.cancelar({ idPagamento }, { observacao })
        .then(() => {
          this.$emit('recarregar', 'PENDENTE');

          if (this.isStatusPendente) {
            this.$toast(this.$t('message.pagamento_fluxo_cancelado'));
          }

          if (this.isStatusVencido) {
            this.$toast(this.$t('message.pagamento_fluxo_recuperado'));
          }
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    irProximaPagina(irProximo) {
      if (irProximo) {
        this.$emit('PagamentoAcaoFluxo_irProximo');
      }
    },
    abrirAcompanhamento() {
      const idPagamento = this.getIdPagamento();
      this.workflowPagamentoAcaoResource
        .existeFluxoCadastrado({ idPagamento })
        .then((res) => {
          const existeFluxo = res.data;
          if (!existeFluxo) {
            const msg = this.$t('message.pagamento_finalizada_sem_fluxo');
            this.$toast(msg);
            return;
          }
          this.exibeModalAcompanhamento(idPagamento);
        });
    },
    exibeModalAcompanhamento(idPagamento) {
      this.workflowPagamentoAcaoResource.statusPasso({ idPagamento }, { idPagamento })
        .then((res) => {
          if (res.data.passos
                && (res.data.passos[0].statusPasso === 'EM_ANALISE' || res.data.passos[0].statusPasso === 'REPROVADO')) {
            res.data.passos.splice(1);
          }
          this.statusPassos = res.data;
          this.$refs.modalAcompanhamento.open();
        })
        .catch((err) => {
          this.$toast(this.$t(err));
        });
    },
    getTiposVerbas() {
      return this.tiposVerbas ? this.tiposVerbas : this.acao.tiposVerbas;
    },
    getIdPagamento() {
      return this.idPagamento ? this.idPagamento : this.acao.idPagamento;
    },
    getIdAcao() {
      return this.idAcao ? this.idAcao : this.acao.idAcao;
    },
    getStatusPagamento() {
      return this.status ? this.status : this.acao.statusPagamento;
    },
    getPodeCancelar() {
      if (this.podeCancelar) {
        return this.podeCancelar;
      }
      if (this.acao && this.acao.podeCancelar) {
        return this.acao.podeCancelar;
      }
      return false;
    },
    getPodeReprovar() {
      if (this.podeReprovar) {
        return this.podeReprovar;
      }
      if (this.acao && this.acao.podeReprovar) {
        return this.acao.podeReprovar;
      }
      return false;
    },
    carregarConfiguracao() {
      const idAcao = this.getIdAcao();
      return this.configuracaoResource.buscarConfigSnapshot({ idAcao }).then((res) => {
        this.config = res.data;
      })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
  },
  beforeMount() {
    this.carregaInformacoesFluxo();
  },
  mounted() {
    this.carregarConfiguracao();
  },
};
</script>
