import { render, staticRenderFns } from "./PagamentoDetalhes.vue?vue&type=template&id=833605b4&"
import script from "./PagamentoDetalhes.vue?vue&type=script&lang=js&"
export * from "./PagamentoDetalhes.vue?vue&type=script&lang=js&"
import style0 from "./PagamentoDetalhes.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBreadcrumbsItem } from 'vuetify/lib/components/VBreadcrumbs';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBreadcrumbs,VBreadcrumbsItem,VCard,VCol,VContainer,VDivider,VExpansionPanels,VPagination,VRow})
