<template>
  <v-expansion-panel>
    <v-expansion-panel-header class="headline">
      <div>
        <span>{{ $tc('label.evidencias', 1)}}</span>
        <v-tooltip bottom v-if="habilitarUpload">
          <template v-slot:activator="{ on }">
            <v-btn class="ma-0" style="margin-top: -4px !important;" icon v-on="on" @click.stop="$refs.uploadArquivo.open()">
              <v-icon>attach_file</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('title.upload_evidencia') }}</span>
        </v-tooltip>
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <list-files
        @ListFiles_ITENS_ALTERADOS="itensAlterados"
        @ListFiles__arquivoRemovido="arquivoDeletado"
        :habilitar-exclusao="habilitarUpload"
        ref="listFiles"
        :uri="uri"
        v-if="acao.idPagamento">
      </list-files>
    </v-expansion-panel-content>
    <upload-file-form
        v-if="habilitarUpload"
        ref="uploadArquivo"
        :title="$t('label.upload_evidencia')"
        :url="uriUpload"
        :auto-process-queue="false"
        @UPLOADFILE_SENDING="enviandoArquivo"
        @UPLOADFILE_FILE_ADDED="preencherDescricaoComNomeArquivo"
        @UploadArquivo__success="uploadSucesso">
      <v-text-field
          v-model="descricaoArquivo"
          :rules="[rules.required]"
          :counter="150"
          maxlength="150"
          :label="`${$t('label.descricao')}*`"
          required
      ></v-text-field>
    </upload-file-form>
  </v-expansion-panel>
</template>

<script>
import { generateComputed } from '../../../common/functions/roles-computed-generator';
import basePath from '../../../common/functions/api-resource';
import ListFiles from '../../../shared-components/files/ListFiles';
import UploadFileForm from '../../../shared-components/upload/UploadFileForm';

export default {
  name: 'PagamentoArquivos',
  props: {
    acao: Object,
    habilitarEvidencia: Boolean,
  },
  data() {
    return {
      esperar: false,
      descricaoArquivo: null,
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
    };
  },
  components: {
    ListFiles,
    UploadFileForm,
  },
  computed: {
    ...generateComputed('PGTO', [
      'canEdit',
    ]),
    uri() {
      const urlUpload = basePath('job', 'arquivo/acao');
      return `${urlUpload}/${this.acao.idAcao}/apuracao/${this.acao.idApuracao}/pagamento/${this.acao.idPagamento}`;
    },
    uriUpload() {
      const urlUpload = basePath('job', 'arquivo/acao');
      return `${urlUpload}/${this.acao.idAcao}/apuracao/${this.acao.idApuracao}/pagamento/${this.acao.idPagamento}/upload`;
    },
    habilitarUpload() {
      return this.habilitarEvidencia && this.canEdit
          && (this.acao.statusPagamento === 'PENDENTE' || this.acao.statusPagamento === 'EM_ANALISE');
    },
  },
  methods: {
    itensAlterados(payload) {
      this.$emit('PagamentoArquivos_itensAlterados', payload);
    },
    arquivoDeletado() {
      this.$emit('PagamentoArquivos_removido');
    },
    enviandoArquivo(data, xhr, formData) {
      formData.append('descricao', encodeURI(this.descricaoArquivo));
    },
    preencherDescricaoComNomeArquivo(file) {
      this.descricaoArquivo = file.name;
    },
    uploadSucesso() {
      if (this.$refs.listFiles) {
        setTimeout(() => this.$refs.listFiles.refresh(), 1E3);
      }
      this.$toast(this.$t('label.upload_arquivo_sucesso'));
      this.$emit('PagamentoArquivos_uploadSucesso');
    },
  },
  watch: {
    acao() {
      if (this.$refs.listFiles) {
        if (!this.esperar) {
          this.esperar = true;
          setTimeout(() => {
            this.$refs.listFiles.refresh();
            this.esperar = false;
          }, 2E2);
        }
      }
    },
  },
};
</script>
